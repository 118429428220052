<template>
  <div class="main-content">
    <h2 class="text-align-center mb-2">
      {{ $t('page-titles.aws-entitlements') }}
    </h2>

    <!-- Description text -->
    <i18n path="entitlements.description"
      tag="p"
      class="text-align-left text-muted">
      <template #inUse>
        <em>{{ $t('entitlements.in-use') }}</em>
      </template>
    </i18n>

    <!-- Entitlements selection -->
    <div class="text-align-center">
      <el-form ref="entitlementsFilter"
        :model="entitlementsForm"
        inline>
        <el-form-item :label="$t('entitlements.product-type')">
          <el-select v-model="entitlementsForm.productType"
            placeholder="Select"
            size="mini"
            @change="getEntitlements">
            <el-option v-for="item in productTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value" />
          </el-select>
        </el-form-item>

        <el-form-item :label="$t('entitlements.status')">
          <el-select v-model="entitlementsForm.status"
            placeholder="Select"
            size="mini"
            @change="getEntitlements">
            <el-option v-for="item in statuses"
              :key="item.value"
              :label="item.label"
              :value="item.value" />
          </el-select>
        </el-form-item>
      </el-form>
    </div>

    <el-table v-loading="loading"
      :element-loading-text="$t('entitlements.loading')"
      :data="entitlementList"
      :empty-text="$t('entitlements.no-match')"
      stripe
      class="w-100">
      <!-- Agreement ID -->
      <el-table-column prop="vendorAgreementId"
        :label="$t('entitlements.agreement-id')"
        width="200"
        sortable />

      <!-- Vendor Customer ID -->
      <el-table-column prop="vendorCustomerId"
        :label="$t('entitlements.vendor-account-id')"
        width="210"
        sortable />

      <!-- SKU -->
      <el-table-column prop="sku"
        :label="$t('entitlements.sku')"
        width="180"
        sortable />

      <!-- Start Date -->
      <el-table-column prop="entitlementStartDate"
        :label="$t('entitlements.start-date')"
        width="230"
        sortable>
        <template #default="{row}">
          <span>{{ formatDate(row.entitlementStartDate) }}</span>
        </template>
      </el-table-column>

      <!-- End Date -->
      <el-table-column prop="entitlementEndDate"
        :label="$t('entitlements.end-date')"
        width="240"
        sortable>
        <template #default="{row}">
          <span>{{ formatDate(row.entitlementEndDate) }}</span>
        </template>
      </el-table-column>

      <!-- Service ID -->
      <el-table-column prop="productId"
        :label="$t('entitlements.service-id')"
        width="170"
        sortable>
        <template #default="{row}">
          <span>{{ row.productId ? getProductUid(row.productId) : '-' }}</span>
        </template>
      </el-table-column>

      <!-- Status -->
      <el-table-column prop="status"
        :label="$t('entitlements.status')"
        width="120"
        fixed="right"
        align="center"
        sortable>
        <template #default="{row}">
          <div>
            <el-tag :type="statusTagType(row.status)"
              class="full-width">
              {{ startCase(row.status) }}
            </el-tag>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
// NOTE: The AWS project is pure chaos so this component may or may not even be around for very long.
import { mapGetters, mapMutations } from 'vuex'
import { DateTime } from 'luxon'
import { startCase } from 'lodash'
import sdk from '@megaport/api-sdk'

import captureSentryError from '@/utils/CaptureSentryError'
import { G_PRODUCT_TYPE_VXC, G_PRODUCT_TYPE_MCR2, G_PRODUCT_TYPE_MEGAPORT, G_PRODUCT_TYPE_MVE } from '@/Globals'

// Entitlement statuses
const AVAILABLE = 'AVAILABLE'
const IN_USE = 'IN_USE'
const EXPIRED = 'EXPIRED'

export default {
  data() {
    return {
      loading: true,
      entitlementsForm: {
        productType: null,
        status: null,
      },
      productTypes: [
        {
          label: this.$t('general.all'),
          value: null,
        },
        {
          label: this.$t('productNames.port'),
          value: G_PRODUCT_TYPE_MEGAPORT,
        },
        {
          label: this.$t('productNames.mcr'),
          value: G_PRODUCT_TYPE_MCR2,
        },
        {
          label: this.$t('productNames.mve'),
          value: G_PRODUCT_TYPE_MVE,
        },
        {
          label: this.$t('productNames.vxc'),
          value: G_PRODUCT_TYPE_VXC,
        },
      ],
      statuses: [
        {
          label: this.$t('general.all'),
          value: null,
        },
        {
          label: this.$t('entitlements.available'),
          value: AVAILABLE,
        },
        {
          label: this.$t('entitlements.in-use'),
          value: IN_USE,
        },
        {
          label: this.$t('entitlements.expired'),
          value: EXPIRED,
        },
      ],
      entitlementList: [],
    }
  },

  computed: {
    ...mapGetters('Services', ['getServicesById']),
  },

  mounted() {
    this.getEntitlements()
  },

  methods: {
    startCase,
    ...mapMutations('Notifications', ['notifyBad']),

    /**
     * Returns the appropriate tag type based on the status
     * @param {string} status Entitlement status
     */
    statusTagType(status) {
      switch (status) {
        case AVAILABLE:
          return 'success'
        case IN_USE:
          return 'primary'
        case EXPIRED:
          return 'danger'
        default:
          return 'info'
      }
    },
    formatDate(date) {
      return DateTime.fromMillis(date).toFormat('y-LL-dd')
    },
    /**
     * Get a list of entitlements from the API given the product type and status
     */
    getEntitlements() {
      this.loading = true

      sdk.instance
        .awsEntitlements(this.entitlementsForm.productType, this.entitlementsForm.status)
        .then(({ entitlements }) => {
          this.entitlementList = entitlements
        })
        .catch(error => {
          const errorStr = error.data?.message ?? error
          this.notifyBad({
            title: this.$t('entitlements.failed'),
            message: errorStr,
          })
          if (!error.data?.message) {
            captureSentryError(error)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },

    // API: This is very temporary and will very soon be replaced with the productUid directly from the API
    getProductUid(productId) {
      const product = this.getServicesById(productId)[0]
      return product?.productUid.split('-')[0]
    },
  },
}
</script>

<style lang="scss" scoped>
.main-content {
  max-width: 1470px;
  min-width: 400px;
  margin: 20px auto;
  padding: 20px;
  background-color: var(--color-white);
  border: 1px solid var(--border-color-base);
  border-radius: var(--border-radius-base);
}

.fas {
  font-size: 1.2em;
}
</style>
